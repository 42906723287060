<template>
    <div class="container" >
      <div class="row about-us-row">
		  <center>
		 <h2 class="iq-title">{{contactUsData.titleAbout}}</h2>
         <div class="cols-sm-12 col-lg-8" style="display: flex; justify-content: center; align-items: center; mix-blend-mode: lighten;">
            <img width="100%" :src="contactUsData.imageAbout.url" class="d-block mx-auto attachment-large size-large" alt="" loading="lazy" >
         </div>
		  </center>
	  </div>
	  <div class="row about-us-row">
         <div class="cols-sm-12 col-lg-12 col-md-12">
            <div class=" text-left iq-title-box iq-title-default">
               <p class="iq-title-desc">{{contactUsData.descriptionAbout}}</p>
               <div class="row mt-2">
                  <div class="col-md-4">
                     <div class="counter">
                        <span class="counter-number" data-duration="2000" data-to-value="4" data-from-value="0" data-delimiter=",">
                           {{contactUsData.statisticValueLeft}}
                        </span>
                     </div>
                     <div class="counter-title">{{contactUsData.statisticTitleLeft}}</div>
                  </div>
                  <div class="col-lg-4 col-md-4 col-sm-12">
                     <div class="iq-contact-list" data-element_type="column">
                     <div class="counter">
                        <span class="counter-number" data-duration="2000" data-to-value="4" data-from-value="0" data-delimiter=",">
                           {{contactUsData.statisticValueCenter}}
                        </span>
                     </div>
                     <div class="counter-title">{{contactUsData.statisticTitleCenter}}</div>
                     </div>
                  </div>
                  <div class="col-md-4">
                     <div class="iq-contact-list" data-element_type="column">
                     <div class="counter">
                        <span class="counter-number" data-duration="2000" data-to-value="4" data-from-value="0" data-delimiter=",">
                           {{contactUsData.statisticValueRight}}
                        </span>
                     </div>
                     <div class="counter-title"> {{contactUsData.statisticTitleRight}}</div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
export default {
  name: 'ContactUs',
  props: {
    contactUsData: Object
  }
}
</script>
