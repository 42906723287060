<template>
	<main id="main" class="site-main">
		<Team :aboutData="aboutData" :teamItems="teamItems" />
		<ContactUs :contactUsData="contactUsData" />
	</main>
</template>
<script>
import { core } from "../../config/pluginInit";
import ContactUs from "./Components/NLSE/ContactUs.vue";
import Team from "./Components/NLSE/Team.vue";

export default {
	name: "About",
	components: {
		ContactUs,
		Team,
	},
	mounted() {
		core.index();
	},
	data() {
		return {
			aboutData: Object,
			teamItems: [],
			contactUsData: Object,
		};
	},
	async created() {
		// Get Data from the API
		// this.aboutData = data.aboutPages[0]
		// this.teamItems = data.teamMembers
		// this.contactUsData = data.aboutPages[0]
	},
};
</script>
