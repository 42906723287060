<template>
<div>
   <div class="container">
      <div class="row">
         <div class="col-lg-12 col-sm-12">
            <div class="text-center iq-title-box iq-title-default">
               <h2 class="iq-title text-capitalize">
                  {{aboutData.titleTeam}}
               </h2>
               <p class="iq-title-desc">{{aboutData.descriptionTeam}}</p>
            </div>
         </div>
      </div>
   </div>
   <div class="container">
      <div class="row about-us-detail">
         <div class="col-sm-12 col-md-3 col-lg-3"></div>
         <div class="col-sm-12 col-md-3 col-lg-3 mr-5" v-for="(item,index) of teamItems" :key="index" >
            <div class="image-box">
               <img :src="item.image.url" class="attachment-large size-large" alt="" loading="lazy">
            </div>
            <div class="icon-box-content">
               <div class="widget-container">
                  <p class="h4">{{item.title}}</p>
               </div>
               <div class="widget-container">
                  <h3 class="pb-4">{{item.name}}</h3>
               </div>
            </div>
         </div>
         <div class="col-sm-12 col-md-3 col-lg-3"></div>
      </div>
   </div>
</div>
</template>
<script>
export default {
  name: 'Team',
  props: {
    aboutData: Object,
    teamItems: Array
  },
  data () {
    return {
      lists: [
        {
          src: require('@/assets/images/frontend/about-us/02.jpg'),
          name: 'Andre Gudger',
          titel: 'CEO'

        },
        {
          src: require('@/assets/images/frontend/about-us/03.jpg'),
          name: 'Michael Blake',
          titel: ''

        },

        {
          src: require('@/assets/images/frontend/about-us/04.jpg'),
          name: 'Liam Banks',
          titel: ''

        },
        {
          src: require('@/assets/images/frontend/about-us/05.jpg'),
          name: 'Justin Ross',
          titel: ''

        }
      ]
    }
  }
}
</script>
